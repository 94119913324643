<template>
  <b-container class="complexitytimeoffsetmanagement">
    <b-row>
      <b-col cols="12" md="12" lg="10" offset-lg="1">
        <h2>Complexity Time Offsets</h2>
        <b-button variant="primary" class="mx-1 my-1" @click="showAddOffset"
          >Set Complexity Offset</b-button
        >
        <b-button variant="primary" class="mx-1 my-1" @click="refreshOffsets"
          >Refresh Offsets</b-button
        >
        <b-table
          striped
          small
          hover
          :fields="offsetFields"
          :items="offsetList"
          :sort-by.sync="offsetSortBy"
        >
          <template #cell(actions)="data">
            <b-button
              class="mx-2"
              size="sm"
              @click="showDeleteOffset(data.item.id)"
              variant="outline-danger"
              >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
        <p><i>The default off-set is 14 days.</i></p>
      </b-col>
    </b-row>
    <b-modal
      ref="newOffsetModal"
      title="Create new offset"
      hide-header-close
      @ok="addOffset"
      :ok-disabled="!newOffsetValid"
    >
      <b-form-group
        label="Complexity level:"
        label-for="newComplexityLevel"
      >
        <b-form-input
          id="newComplexityLevel"
          v-model="newComplexityLevel"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Offset:" label-for="newOffset">
        <b-form-input id="newOffset" v-model="newOffset"></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal ref="deleteOffsetModal" title="Delete offset?" hide-header-close @ok="deleteOffset">
      <template #modal-ok>Confirm</template>
      <label>Offset ID:</label>
      <b-form-input v-model="deleteOffsetID" disabled></b-form-input>
    </b-modal>

  </b-container>
</template>

<script>
import { defaultToastBody, defaultToastConfig, formatDateAsStr } from "@/util";

export default {
  name: "ComplexityTimeOffsetManagement",
  data: function () {
    return {
      newComplexityLevel: "",
      newOffset: 0,
      deleteOffsetID: null,
      offsetFields: [
        "id",
        { key: "complexity", sortable: true },
        { key: "offset", sortable: true, label: "Time Offset" },
        { key: "actions", label: "Actions" },
      ],
      offsetSortBy: "complexity",
    };
  },
  computed: {
    offsetList() {
      return this.$store.state.complexityTimeOffsetsList;
    },
    /**
     * True if the new offset is valid.
     * 
     * NOTE: the offset is input as a string, then cast as an int. The check `typeof this.newOffset === number` will always be false.
     */
    newOffsetValid: function () {
      return (
        typeof this.newComplexityLevel === "string"
        && this.newComplexityLevel.length > 0
        && this.newOffset > 0
      );
    },
  },
  methods: {
    // TODO: Move to common utility functions?
    getTodayStr: function () {
      /** Returns today in YYYY-MM-DD format. */
      let today = new Date();
      return formatDateAsStr(today);
    },
    showAddOffset: function () {
      this.newComplexityLevel = "";
      this.newOffset = 0;
      this.$refs["newOffsetModal"].show();
    },
    addOffset: function () {
      /** Add a new offset. The new complexity level in is stripped of whitespace. */
      let payload = {
        complexity_level: this.newComplexityLevel.trim(),
        offset: this.newOffset,
      };
      this.$store
        .dispatch("createComplexityTimeOffset", payload)
        .then((res) => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "Succesfully set offset";
          toastConfig.variant = "success";
          this.$bvToast.toast(`Set offset ID: ${res.id}`, toastConfig);
          this.refreshOffsets();
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    deleteOffset: function () {
      this.$store
        .dispatch("deleteComplexityTimeOffset", this.deleteOffsetID)
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "Offset deleted.";
          toastConfig.variant = "success";
          this.$bvToast.toast(`Deleted offset ID: ${this.deleteOffsetID}`, toastConfig);
          this.refreshOffsets();
        })
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showDeleteOffset: function (offsetId) {
      this.deleteOffsetID = offsetId;
      this.$refs["deleteOffsetModal"].show();
    },
    showImportCSV: function () {
      this.importRulesCSV = null;
      this.$refs["importRulesCSVModal"].show();
    },
    _validateFile: function (_file) {
      if (!_file) {
        return false;
      }
      let fparts = _file.name.split(".");
      if (fparts.length <= 1 || fparts[fparts.length - 1] !== "csv") {
        return false;
      }
      return true;
    },
    _checkFiles() {
      if (!this.importRulesCSVFormValid) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "No file set!";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `Please browse or drop a file on the file box.`,
          toastConfig
        );
        return false;
      }
      return true;
    },
    /**
     * Refresh the table of rules.
     */
    refreshOffsets: function () {
      this.$store
        .dispatch("getComplexityTimeOffsets")
        .catch((err) => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
  },
  components: {},
  created() {},
  mounted() {
    if (this.$store.state.complexityTimeOffsetsList.length === 0) {
      this.refreshOffsets();
    }
  },
};
</script>
